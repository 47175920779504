@keyframes moveUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer-component {
  grid-column: -1;
  display: grid;
}

.footer-border {
  grid-column: -1;
  background-color: blue;
  grid-row: 0.5;
  display: grid;
  height: 10rem;
}

.view-btn {
  grid-row: 0.5;
  grid-column: -1;
  justify-self: center;
  align-items: center;
  border: none;
  padding: 1rem;
  height: 5rem;
  width: 10rem;
  border-top-left-radius: 12rem;
  border-top-right-radius: 12rem;
  color: white;
  background-color: blue;
  align-self: end;
}

.view-btn-2 {
  background-color: blue !important;
}

.fixed {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .footer {
    display: none;
  }
}

.footer {
  grid-column: -1;
  background-color: #176299;


}

.footer__content {
  padding: 5rem 3.5rem 1rem 3.5rem;
}

.footer__content-1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4rem;
}

.footer__image {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 3rem;
}

.footer__image--icon {
  /* font-size: 2rem; */
  /* color: white; */
  /* cursor: pointer; */
  width: 2rem;
  height: 2rem;
}

.footer__bottom {
  margin-top: 5rem;
  grid-column: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__border {
  margin-top: 2rem;
  height: 0.1rem;
  width: 100%;
  background-color: white;
  margin-bottom: 1.2rem;
}

.footer__view-btn {
  font-size: 2rem;
}

.column-1__text {
  font-size: 1.8rem;
  color: white;
  margin-left: 1rem;
  font-weight: 500;
  text-align: initial;
}

.column-1__border {
  margin-top: 0.5rem;
  margin-left: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.column-1__border--1 {
  height: 0.2rem;
  background-color: white;
}

.column-1__content {
  margin-top: 3rem;
}

.row {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.row__image {
  display: flex;
}

.row__image--icon {
  font-size: 2rem;
  color: white;
}

.row__para {
  color: white;
}

.footer-2 {
  grid-column: -1;
  background-color: blue;
}

.footer-2__text {
  color: white;
}

.footer-2__content {
  padding: 6rem 18rem 5rem 18rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-row-gap: 3rem;
}

.footer-2__row {
  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-column-gap: 1rem;
}

.footer-2__row--links {
  display: flex;
  flex-wrap: wrap;
}

.footer-2__row--name {
  margin-right: 2rem;
}

.footer-2__row--para {
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5rem;
}

.footer-2__row--para::after {
  content: " ";
  display: block;
  height: 1.2rem;
  width: 0.1rem;
  background-color: white;
  margin: 0 2rem;
}

.footer-2__row--border {
  height: 1.5rem;
  background-color: white;
  width: 0.1rem;
  justify-self: center;
  align-self: center;
}

@media only screen and (min-width: 600px) {
  .mobile-footer {
    display: none;
  }
}


.mobile-footer {
  grid-column: -1;
  background-color: #134a7a;


}

.mobile-footer__content {
  padding: 2rem;
}

.mobile-footer__row--link {
  margin-top: 1rem;
}

.mobile-footer__row--text {
  color: white;
}

.mobile-footer__row--para {
  color: white;
}

.mobile-footer__row--para::after {
  margin-right: 1rem;
}

.mobile-footer__image {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 3rem;
}

.mobile-footer__image--icon {
  font-size: 2rem;
  color: white;
}
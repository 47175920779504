@import url("https://fonts.googleapis.com/css?family=Arvo&display=swap");
.section-header {
  font-size: 4.8rem;
  color: #1c1c1c;
  font-weight: bold;
  margin: 0rem 0rem 3rem 0rem;
  line-height: 6rem;
  font-family: Arvo;
  word-wrap: normal;
}
.course-header {
  font-size: 4.8rem;
  color: #1c1c1c;
  font-weight: bold;
  margin: 0rem 0rem 3rem 0rem;
  line-height: 6rem;
  font-family: Arvo;
  word-wrap: normal;
}
.section-para {
  font-size: 1.7rem;
  color: #888888;
  margin-bottom: 6rem;
  text-align: center;
}
@media only screen and (max-width: 780px) {
  .section-header {
    font-size: 4.22rem;
    color: #1c1c1c;
    font-weight: bold;
    margin: 0rem 0rem 3rem 0rem;
    line-height: 6rem;
    font-family: Arvo;
    word-wrap: normal;
  }

  .section-para {
    font-size: 1.5rem;
    color: #888888;
    margin-bottom: 6rem;
    text-align: center;
  }
  .course-header {
    font-size: 4.22rem;
    color: #1c1c1c;
    font-weight: bold;
    margin: 0rem 0rem 3rem 0rem;
    line-height: 6rem;
    font-family: Arvo;
    word-wrap: normal;
    margin-bottom: 10rem;
  }
}

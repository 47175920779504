.highlight-card {
  margin-top: 40px;
}

.row-highlight {
  margin-bottom: 0px;
  padding-bottom: 100px;
}

.highlight-card .ant-card {
  height: 100%;

  border-radius: 4px;
  background-color: white;
}

.highlight-card h2 {
  color: #ffffff;
  margin-bottom: 2rem;
}

.highlight-card p {
  color: #ffffff;
  margin-bottom: 2rem;
}

.highlight-card img {
  height: 6rem;
  width: 100%;
}

.highlight-card .ant-card-body {
  padding: 3.2rem;
}

.placement .slick-dots li button:before {
  font-size: 0px !important;
}

.placement .slick-dots-bottom li {
  margin-top: 9rem !important;
}
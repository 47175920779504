@media only screen and (max-width: 600px) {
  .stepper .ant-steps-item-title::after {
    display: none !important;
  }

  .progress .ant-steps-item-tail {
    display: none !important;
  }

  .progress .ant-steps-item-icon {
    margin-right: 0.5rem !important;
  }
}

.ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #bfbfbf !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #faca35 !important;
  border-color: #faca35 !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: white !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail {
  background-color: #bfbfbf !important;
}
.progress .ant-steps-item-tail {
  margin-left: 8rem !important;
}

.progress {
  display: flex !important;
  flex-wrap: wrap !important;
}
.progress .ant-steps-item-title {
  color: white !important;
  font-size: 1.5rem;
}
.description-text {
  color: white !important;
  padding: 3rem;
  margin-top: 2rem;
  font-size: 1.7rem;
  font-family: Muli;
  text-align: center;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #faca45 !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #000 !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #faca45 !important;
}
.ant-steps-item-icon {
  cursor: pointer !important;
}

.testimonial-carousel-row {
  padding: 0rem 3rem !important;
  text-align: left;
}

.testimonial p {
  text-align: left;
}

.testimonial h3,
h4 {
  text-align: center;
}

.testimonial .ant-divider-vertical {
  height: 100%;
}

.testimonial .ant-card-bordered {
  border: none;
}

.ant-divider-horizontal {
  /* display: block;
  clear: both;
  width: 65px !important;
  min-width: 65px !important;
  height: 1px !important;
  background-image: linear-gradient(to bottom, #00d3a8, #01c0cf) !important;
  margin: 16px 0px 21px 0 !important;
  transform: translateX(-145px) !important; */
  height: 2px !important;
  color: #707070;
  width: 38% !important;
  min-width: 38% !important;
  margin-left: unset;
  margin: 7.5px 0 !important;
}

.testimonial-carousel-row .ant-card-body {
  padding: 0px !important;
}

.testimonial .ant-card-meta-detail {
  text-align: left;
}

.testimonial .ant-card-meta-title {
  margin-top: 24px;
}

.testimonial-carousel-row > .ant-card-cover > img {
  object-fit: fill;
  height: 30rem;
}

@import "admission.css";
@import "placement.css";
@import "quality.css";
@import "section.css";
@import "courses.css";
@import "testimonial.css";
@import "registration.css";
@import "footer.css";

html {
  font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0rem;
  padding: 0rem;
}

h1 {
  font-size: 5rem;
}
.header0-menu > .ant-menu {
  width: 101% !important;
}
.section-start-end {
  padding: 7rem 0rem 10rem 0rem;
}
.admission-start-end {
  padding: 3rem 0rem 10rem 0rem;
}
.bg-color-violet {
  background-image: linear-gradient(to bottom, #3c1a58, #3d3975);
}

.bg-color-white {
  background-color: #ffffff;
}

.text-color-white {
  color: white !important;
}

.text-color-off-white {
  color: white !important;
}

.header0 .home-page {
  padding: 0 24px 0 40px !important;
}

@media only screen and (max-width: 780px) {
  .header0 .ant-menu .ant-menu-item-selected a {
    color: white !important;
  }

  .header0 .home-page {
    padding: 0 2rem !important;
  }
  .header0-menu > .ant-menu {
    width: 101% !important;
  }
  .course-section-background {
    background: linear-gradient(
      to bottom,
      #3c1a58 0%,
      #3c1a58 25%,
      white 25%,
      white 100%
    ) !important;
  }
}
.home-page-wrapper {
  background-color: white !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
}
.course-section-background {
  background: linear-gradient(
    to bottom,
    #3c1a58 0%,
    #3c1a58 35%,
    white 35%,
    white 100%
  );
}
.footer-curve {
  background-color: white;
  border-bottom-left-radius: 50%;
  height: 80px;
  border-bottom-right-radius: 50%;
}

.ant-carousel .slick-dots-bottom {
  bottom: -1rem !important;
}
.points {
  text-align: left;
  font-size: 1.6rem;
  font-family: Roboto;
  color: #000000;
  display: flex;
}
.benefit-col {
  padding: 10px 14px 10px 120px !important;
}
.tag-title {
  font-size: 2.3rem;
  color: #fff;
}
.tag-description {
  font-size: 1.5rem;
  color: #fff;
}
.tags {
  margin-top: 2rem !important;
}

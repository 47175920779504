@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono|Roboto+Slab&display=swap");
.registration-form-tag.ant-tag-checkable {
  border-color: #3c1a58;
  border-radius: 25px;
  font-size: 13px;
  color: #3c1a58;
  padding: 2px 10px;
  margin-top: 0.5rem;
}

.registration-page-background {
  padding: 0px;
  margin: 0px;
}

.registration-ant-form {
  padding: 2rem;
}

@media only screen and (max-width: 600px) {
  .registration-ant-form {
    padding: 3rem 3rem 0 3rem;
  }
}

.register-form .ant-input {
  border-radius: 2.25rem !important;
  background-color: #f0f2f7;
  max-width: 254px;
}

.register-select .ant-select-selection {
  border-radius: 2.25rem !important;
  background-color: #f0f2f7;
}

@media only screen and (max-width: 600px) {
  .register-form-column {
    padding: 3rem !important;
  }
}

.register-form-column {
  padding: 3.6rem 0rem 4rem 0rem;
}

@media only screen and (max-width: 600px) {
  .register-text {
    clip-path: unset !important;
  }
}

@media only screen and (max-width: 600px) {
  .register-text__glob-header-img {
    display: none;
  }
}

.register-form p {
  padding-top: 2.8rem;
  padding-left: 3.8rem;
  text-align: left;
  font-size: 2.4rem;
  font-weight: bold;

  color: #3c1a58;
}

.register-text .ant-form label {
  font-size: 1.1rem !important;
  color: #3c1a58;
  font-weight: 750;
}

.register-text .ant-form-item-label {
  line-height: 16px;
  padding: 0rem;
}

.register-checkbox span {
  font-size: 1.1rem !important;
  font-weight: normal;
  color: #818080;
}

.register-form {
  text-align: center;
  border: none;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);

  padding: 0 !important;
  max-width: 294px;
  background-color: #ffffff !important;
  border-radius: 0.5rem;
}

.register-form .ant-tag-checkable-checked {
  background-color: #3c1a58;
  color: white !important;
}

.register-form .ant-btn-primary {
  background-color: #3c1a58;
  border: none !important;
  /* border-radius: 4rem !important; */
  height: 3rem;
  max-width: 251px;
}
.register-form .ant-btn-primary:hover {
  background-color: #9b95f5 !important;
  box-shadow: 0 5px 20px 0;
  border-color: none !important;
}
.register-form .ant-btn-primary:focus {
  background-color: #635db6 !important;
  border-color: none !important;
}
.register-form .ant-btn {
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}
.registration-ant-form .ant-form-explain {
  font-size: 1rem;
}
.admission-college {
  font-family: Roboto Slab;
  font-size: 3.2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-bottom: 3rem;
}
.admission-heading {
  font-family: Roboto Slab;
  font-size: 5.2rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-bottom: 5rem;
  margin-right: 6rem;
  word-wrap: normal;
}
.admission-para {
  font-family: Roboto;
  font-size: 1.76rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.106rem;
  text-align: left;
  color: #ffffff;
}
.know-more {
  background-color: #c4e538 !important;
  border: none !important;
  border-radius: 4rem !important;
  height: 2.5rem;
  font-size: 1.2rem;
}
.ant-form-item {
  margin-bottom: 8px !important;
}
.left-text-register {
  padding: 7rem 10rem 4rem 8rem;
  text-align: left;
  width: 100%;
}
@media only screen and (max-width: 780px) {
  .left-text-register {
    padding: 7rem 2rem 2rem 2rem;
    text-align: left;
    width: 100%;
  }
  .admission-heading {
    font-family: Roboto Slab;
    font-size: 4.5rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 5rem;
    margin-right: 6rem;
    word-wrap: normal;
  }
  .register-form .ant-btn {
    font-size: 1.2rem;
    margin-bottom: 1.8rem;
  }
}

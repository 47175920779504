@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
.service-card .ant-card-meta-description {
  color: #888888;

  line-height: 3ex;
  height: 15ex;
  overflow: unset;
  display: unset;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
}
.service-card .ant-card-meta-title {
  white-space: unset;
}

.service-card img {
  width: 100%;
  height: 20rem;
}
.service-card {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 108px;
  height: 100%;
}
.services-text .ant-card-meta-title {
  color: #3c1a58;
  font-size: 2rem;
  font-weight: bold;
}
.service-card .ant-card-body {
  margin: 0px !important;
  padding: 24px 0 24px 0 !important;
}

.services-text .ant-card-meta-description {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.03rem;
  margin-bottom: 2rem;
  color: darkgray;
}

.course-card {
  height: 100% !important;
}
.course-name {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bolder;
  color: #000000;
  text-overflow: ellipsis;
}
.course-full-name {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bolder;
  color: #707070;
  text-overflow: ellipsis !important;
  /* margin: 0.5rem 0rem 1.2rem 0rem; */
  /* height: 5rem; */
}
.branch-name {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  color: #7c7c7c;
  margin-top: 5px;
}

.fee-figure {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-top: 0.8rem;
}
.apply-now-btn {
  background-color: #3c1a5b !important;
  color: #fff !important;
  border-color: #3c1a5b !important;
  font-size: 1.4rem !important;
  height: 3.5rem !important;
  font-family: Montserrat !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.course-card .ant-card-body {
  box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.16);
  padding: 20px 14px 20px 24px !important;
  cursor: pointer;
  text-align: left;
}
.course-card .ant-card-meta-description {
  font-family: Montserrat;
  font-size: 1.32rem;
  font-weight: bolder;
  color: #707070;
  text-overflow: ellipsis !important;
  height: 4.7rem;
}
.course-card .ant-card-meta-title {
  font-family: Montserrat;
  /* font-size: 20px; */
  font-weight: bolder;
  color: #000000;
}
.parent-course-card:hover {
  transition: all 0.3s linear;
  transform: translateY(-3px);
}
@media only screen and (max-width: 780px) {
  .course-card .ant-card-body {
    box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.16);
    padding: 20px 14px 12px 24px !important;
    cursor: pointer;
    height: 12rem !important;
  }
  .course-name {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: bolder;
    color: #000000;
    text-overflow: ellipsis;
  }
  .course-full-name {
    font-family: Montserrat;
    font-size: 9px;
    font-weight: bolder;
    color: #707070;
    text-overflow: ellipsis;
    height: 5rem;
  }
  .fee-figure {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 600;
    color: #000000;
    margin-top: 0.8rem;
  }

  .course-card .ant-card-meta-description {
    font-family: Montserrat;
    font-size: 0.9rem;
    font-weight: bolder;
    color: #707070;
    height: 3rem;
    text-overflow: ellipsis !important;
  }
  .course-card .ant-card-meta-title {
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: bolder;
    color: #000000;
  }
}

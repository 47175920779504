@import url(https://fonts.googleapis.com/css?family=Arvo&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono|Roboto+Slab&display=swap);
@media only screen and (max-width: 600px) {
  .stepper .ant-steps-item-title::after {
    display: none !important;
  }

  .progress .ant-steps-item-tail {
    display: none !important;
  }

  .progress .ant-steps-item-icon {
    margin-right: 0.5rem !important;
  }
}

.ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #bfbfbf !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #faca35 !important;
  border-color: #faca35 !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: white !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail {
  background-color: #bfbfbf !important;
}
.progress .ant-steps-item-tail {
  margin-left: 8rem !important;
}

.progress {
  display: flex !important;
  flex-wrap: wrap !important;
}
.progress .ant-steps-item-title {
  color: white !important;
  font-size: 1.5rem;
}
.description-text {
  color: white !important;
  padding: 3rem;
  margin-top: 2rem;
  font-size: 1.7rem;
  font-family: Muli;
  text-align: center;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #faca45 !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #000 !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #faca45 !important;
}
.ant-steps-item-icon {
  cursor: pointer !important;
}

.highlight-card {
  margin-top: 40px;
}

.row-highlight {
  margin-bottom: 0px;
  padding-bottom: 100px;
}

.highlight-card .ant-card {
  height: 100%;

  border-radius: 4px;
  background-color: white;
}

.highlight-card h2 {
  color: #ffffff;
  margin-bottom: 2rem;
}

.highlight-card p {
  color: #ffffff;
  margin-bottom: 2rem;
}

.highlight-card img {
  height: 6rem;
  width: 100%;
}

.highlight-card .ant-card-body {
  padding: 3.2rem;
}

.placement .slick-dots li button:before {
  font-size: 0px !important;
}

.placement .slick-dots-bottom li {
  margin-top: 9rem !important;
}
.quality-image {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.quality-points {
  text-align: justify;
  color: #000000;
}
.quality-image img {
  margin-bottom: 7rem;
  width: 75%;
  height: 75%;
}

.section-header {
  font-size: 4.8rem;
  color: #1c1c1c;
  font-weight: bold;
  margin: 0rem 0rem 3rem 0rem;
  line-height: 6rem;
  font-family: Arvo;
  word-wrap: normal;
}
.course-header {
  font-size: 4.8rem;
  color: #1c1c1c;
  font-weight: bold;
  margin: 0rem 0rem 3rem 0rem;
  line-height: 6rem;
  font-family: Arvo;
  word-wrap: normal;
}
.section-para {
  font-size: 1.7rem;
  color: #888888;
  margin-bottom: 6rem;
  text-align: center;
}
@media only screen and (max-width: 780px) {
  .section-header {
    font-size: 4.22rem;
    color: #1c1c1c;
    font-weight: bold;
    margin: 0rem 0rem 3rem 0rem;
    line-height: 6rem;
    font-family: Arvo;
    word-wrap: normal;
  }

  .section-para {
    font-size: 1.5rem;
    color: #888888;
    margin-bottom: 6rem;
    text-align: center;
  }
  .course-header {
    font-size: 4.22rem;
    color: #1c1c1c;
    font-weight: bold;
    margin: 0rem 0rem 3rem 0rem;
    line-height: 6rem;
    font-family: Arvo;
    word-wrap: normal;
    margin-bottom: 10rem;
  }
}

.service-card .ant-card-meta-description {
  color: #888888;

  line-height: 3ex;
  height: 15ex;
  overflow: unset;
  display: unset;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
}
.service-card .ant-card-meta-title {
  white-space: unset;
}

.service-card img {
  width: 100%;
  height: 20rem;
}
.service-card {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 108px;
  height: 100%;
}
.services-text .ant-card-meta-title {
  color: #3c1a58;
  font-size: 2rem;
  font-weight: bold;
}
.service-card .ant-card-body {
  margin: 0px !important;
  padding: 24px 0 24px 0 !important;
}

.services-text .ant-card-meta-description {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.03rem;
  margin-bottom: 2rem;
  color: darkgray;
}

.course-card {
  height: 100% !important;
}
.course-name {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bolder;
  color: #000000;
  text-overflow: ellipsis;
}
.course-full-name {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bolder;
  color: #707070;
  text-overflow: ellipsis !important;
  /* margin: 0.5rem 0rem 1.2rem 0rem; */
  /* height: 5rem; */
}
.branch-name {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  color: #7c7c7c;
  margin-top: 5px;
}

.fee-figure {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-top: 0.8rem;
}
.apply-now-btn {
  background-color: #3c1a5b !important;
  color: #fff !important;
  border-color: #3c1a5b !important;
  font-size: 1.4rem !important;
  height: 3.5rem !important;
  font-family: Montserrat !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.course-card .ant-card-body {
  box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.16);
  padding: 20px 14px 20px 24px !important;
  cursor: pointer;
  text-align: left;
}
.course-card .ant-card-meta-description {
  font-family: Montserrat;
  font-size: 1.32rem;
  font-weight: bolder;
  color: #707070;
  text-overflow: ellipsis !important;
  height: 4.7rem;
}
.course-card .ant-card-meta-title {
  font-family: Montserrat;
  /* font-size: 20px; */
  font-weight: bolder;
  color: #000000;
}
.parent-course-card:hover {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
@media only screen and (max-width: 780px) {
  .course-card .ant-card-body {
    box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.16);
    padding: 20px 14px 12px 24px !important;
    cursor: pointer;
    height: 12rem !important;
  }
  .course-name {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: bolder;
    color: #000000;
    text-overflow: ellipsis;
  }
  .course-full-name {
    font-family: Montserrat;
    font-size: 9px;
    font-weight: bolder;
    color: #707070;
    text-overflow: ellipsis;
    height: 5rem;
  }
  .fee-figure {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 600;
    color: #000000;
    margin-top: 0.8rem;
  }

  .course-card .ant-card-meta-description {
    font-family: Montserrat;
    font-size: 0.9rem;
    font-weight: bolder;
    color: #707070;
    height: 3rem;
    text-overflow: ellipsis !important;
  }
  .course-card .ant-card-meta-title {
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: bolder;
    color: #000000;
  }
}

.testimonial-carousel-row {
  padding: 0rem 3rem !important;
  text-align: left;
}

.testimonial p {
  text-align: left;
}

.testimonial h3,
h4 {
  text-align: center;
}

.testimonial .ant-divider-vertical {
  height: 100%;
}

.testimonial .ant-card-bordered {
  border: none;
}

.ant-divider-horizontal {
  /* display: block;
  clear: both;
  width: 65px !important;
  min-width: 65px !important;
  height: 1px !important;
  background-image: linear-gradient(to bottom, #00d3a8, #01c0cf) !important;
  margin: 16px 0px 21px 0 !important;
  transform: translateX(-145px) !important; */
  height: 2px !important;
  color: #707070;
  width: 38% !important;
  min-width: 38% !important;
  margin-left: unset;
  margin: 7.5px 0 !important;
}

.testimonial-carousel-row .ant-card-body {
  padding: 0px !important;
}

.testimonial .ant-card-meta-detail {
  text-align: left;
}

.testimonial .ant-card-meta-title {
  margin-top: 24px;
}

.testimonial-carousel-row > .ant-card-cover > img {
  object-fit: fill;
  height: 30rem;
}

.registration-form-tag.ant-tag-checkable {
  border-color: #3c1a58;
  border-radius: 25px;
  font-size: 13px;
  color: #3c1a58;
  padding: 2px 10px;
  margin-top: 0.5rem;
}

.registration-page-background {
  padding: 0px;
  margin: 0px;
}

.registration-ant-form {
  padding: 2rem;
}

@media only screen and (max-width: 600px) {
  .registration-ant-form {
    padding: 3rem 3rem 0 3rem;
  }
}

.register-form .ant-input {
  border-radius: 2.25rem !important;
  background-color: #f0f2f7;
  max-width: 254px;
}

.register-select .ant-select-selection {
  border-radius: 2.25rem !important;
  background-color: #f0f2f7;
}

@media only screen and (max-width: 600px) {
  .register-form-column {
    padding: 3rem !important;
  }
}

.register-form-column {
  padding: 3.6rem 0rem 4rem 0rem;
}

@media only screen and (max-width: 600px) {
  .register-text {
    -webkit-clip-path: unset !important;
            clip-path: unset !important;
  }
}

@media only screen and (max-width: 600px) {
  .register-text__glob-header-img {
    display: none;
  }
}

.register-form p {
  padding-top: 2.8rem;
  padding-left: 3.8rem;
  text-align: left;
  font-size: 2.4rem;
  font-weight: bold;

  color: #3c1a58;
}

.register-text .ant-form label {
  font-size: 1.1rem !important;
  color: #3c1a58;
  font-weight: 750;
}

.register-text .ant-form-item-label {
  line-height: 16px;
  padding: 0rem;
}

.register-checkbox span {
  font-size: 1.1rem !important;
  font-weight: normal;
  color: #818080;
}

.register-form {
  text-align: center;
  border: none;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);

  padding: 0 !important;
  max-width: 294px;
  background-color: #ffffff !important;
  border-radius: 0.5rem;
}

.register-form .ant-tag-checkable-checked {
  background-color: #3c1a58;
  color: white !important;
}

.register-form .ant-btn-primary {
  background-color: #3c1a58;
  border: none !important;
  /* border-radius: 4rem !important; */
  height: 3rem;
  max-width: 251px;
}
.register-form .ant-btn-primary:hover {
  background-color: #9b95f5 !important;
  box-shadow: 0 5px 20px 0;
  border-color: none !important;
}
.register-form .ant-btn-primary:focus {
  background-color: #635db6 !important;
  border-color: none !important;
}
.register-form .ant-btn {
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}
.registration-ant-form .ant-form-explain {
  font-size: 1rem;
}
.admission-college {
  font-family: Roboto Slab;
  font-size: 3.2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-bottom: 3rem;
}
.admission-heading {
  font-family: Roboto Slab;
  font-size: 5.2rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-bottom: 5rem;
  margin-right: 6rem;
  word-wrap: normal;
}
.admission-para {
  font-family: Roboto;
  font-size: 1.76rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.106rem;
  text-align: left;
  color: #ffffff;
}
.know-more {
  background-color: #c4e538 !important;
  border: none !important;
  border-radius: 4rem !important;
  height: 2.5rem;
  font-size: 1.2rem;
}
.ant-form-item {
  margin-bottom: 8px !important;
}
.left-text-register {
  padding: 7rem 10rem 4rem 8rem;
  text-align: left;
  width: 100%;
}
@media only screen and (max-width: 780px) {
  .left-text-register {
    padding: 7rem 2rem 2rem 2rem;
    text-align: left;
    width: 100%;
  }
  .admission-heading {
    font-family: Roboto Slab;
    font-size: 4.5rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 5rem;
    margin-right: 6rem;
    word-wrap: normal;
  }
  .register-form .ant-btn {
    font-size: 1.2rem;
    margin-bottom: 1.8rem;
  }
}

@-webkit-keyframes moveUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes moveUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.footer-component {
  grid-column: -1;
  display: grid;
}

.footer-border {
  grid-column: -1;
  background-color: blue;
  grid-row: 0.5;
  display: grid;
  height: 10rem;
}

.view-btn {
  grid-row: 0.5;
  grid-column: -1;
  justify-self: center;
  align-items: center;
  border: none;
  padding: 1rem;
  height: 5rem;
  width: 10rem;
  border-top-left-radius: 12rem;
  border-top-right-radius: 12rem;
  color: white;
  background-color: blue;
  align-self: end;
}

.view-btn-2 {
  background-color: blue !important;
}

.fixed {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .footer {
    display: none;
  }
}

.footer {
  grid-column: -1;
  background-color: #176299;


}

.footer__content {
  padding: 5rem 3.5rem 1rem 3.5rem;
}

.footer__content-1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4rem;
}

.footer__image {
  display: grid;
  grid-template-columns: repeat(3, -webkit-min-content);
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 3rem;
}

.footer__image--icon {
  /* font-size: 2rem; */
  /* color: white; */
  /* cursor: pointer; */
  width: 2rem;
  height: 2rem;
}

.footer__bottom {
  margin-top: 5rem;
  grid-column: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__border {
  margin-top: 2rem;
  height: 0.1rem;
  width: 100%;
  background-color: white;
  margin-bottom: 1.2rem;
}

.footer__view-btn {
  font-size: 2rem;
}

.column-1__text {
  font-size: 1.8rem;
  color: white;
  margin-left: 1rem;
  font-weight: 500;
  text-align: left;
  text-align: initial;
}

.column-1__border {
  margin-top: 0.5rem;
  margin-left: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.column-1__border--1 {
  height: 0.2rem;
  background-color: white;
}

.column-1__content {
  margin-top: 3rem;
}

.row {
  display: grid;
  grid-template-columns: -webkit-min-content 1fr;
  grid-template-columns: min-content 1fr;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.row__image {
  display: flex;
}

.row__image--icon {
  font-size: 2rem;
  color: white;
}

.row__para {
  color: white;
}

.footer-2 {
  grid-column: -1;
  background-color: blue;
}

.footer-2__text {
  color: white;
}

.footer-2__content {
  padding: 6rem 18rem 5rem 18rem;
  display: grid;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
  grid-row-gap: 3rem;
}

.footer-2__row {
  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-column-gap: 1rem;
}

.footer-2__row--links {
  display: flex;
  flex-wrap: wrap;
}

.footer-2__row--name {
  margin-right: 2rem;
}

.footer-2__row--para {
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5rem;
}

.footer-2__row--para::after {
  content: " ";
  display: block;
  height: 1.2rem;
  width: 0.1rem;
  background-color: white;
  margin: 0 2rem;
}

.footer-2__row--border {
  height: 1.5rem;
  background-color: white;
  width: 0.1rem;
  justify-self: center;
  align-self: center;
}

@media only screen and (min-width: 600px) {
  .mobile-footer {
    display: none;
  }
}


.mobile-footer {
  grid-column: -1;
  background-color: #134a7a;


}

.mobile-footer__content {
  padding: 2rem;
}

.mobile-footer__row--link {
  margin-top: 1rem;
}

.mobile-footer__row--text {
  color: white;
}

.mobile-footer__row--para {
  color: white;
}

.mobile-footer__row--para::after {
  margin-right: 1rem;
}

.mobile-footer__image {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(3, -webkit-min-content);
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 3rem;
}

.mobile-footer__image--icon {
  font-size: 2rem;
  color: white;
}
html {
  font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0rem;
  padding: 0rem;
}

h1 {
  font-size: 5rem;
}
.header0-menu > .ant-menu {
  width: 101% !important;
}
.section-start-end {
  padding: 7rem 0rem 10rem 0rem;
}
.admission-start-end {
  padding: 3rem 0rem 10rem 0rem;
}
.bg-color-violet {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3c1a58), to(#3d3975));
  background-image: linear-gradient(to bottom, #3c1a58, #3d3975);
}

.bg-color-white {
  background-color: #ffffff;
}

.text-color-white {
  color: white !important;
}

.text-color-off-white {
  color: white !important;
}

.header0 .home-page {
  padding: 0 24px 0 40px !important;
}

@media only screen and (max-width: 780px) {
  .header0 .ant-menu .ant-menu-item-selected a {
    color: white !important;
  }

  .header0 .home-page {
    padding: 0 2rem !important;
  }
  .header0-menu > .ant-menu {
    width: 101% !important;
  }
  .course-section-background {
    background: -webkit-gradient(
      linear,
      left top, left bottom,
      from(#3c1a58),
      color-stop(25%, #3c1a58),
      color-stop(25%, white),
      to(white)
    ) !important;
    background: linear-gradient(
      to bottom,
      #3c1a58 0%,
      #3c1a58 25%,
      white 25%,
      white 100%
    ) !important;
  }
}
.home-page-wrapper {
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
}
.course-section-background {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(#3c1a58),
    color-stop(35%, #3c1a58),
    color-stop(35%, white),
    to(white)
  );
  background: linear-gradient(
    to bottom,
    #3c1a58 0%,
    #3c1a58 35%,
    white 35%,
    white 100%
  );
}
.footer-curve {
  background-color: white;
  border-bottom-left-radius: 50%;
  height: 80px;
  border-bottom-right-radius: 50%;
}

.ant-carousel .slick-dots-bottom {
  bottom: -1rem !important;
}
.points {
  text-align: left;
  font-size: 1.6rem;
  font-family: Roboto;
  color: #000000;
  display: flex;
}
.benefit-col {
  padding: 10px 14px 10px 120px !important;
}
.tag-title {
  font-size: 2.3rem;
  color: #fff;
}
.tag-description {
  font-size: 1.5rem;
  color: #fff;
}
.tags {
  margin-top: 2rem !important;
}

body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header0 {
  background: rgba(255, 255, 255, 0.95);
  width: 100%;
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu > .ant-menu {
  line-height: 62px;
  background: transparent;
  color: #3c1a58;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a {
  color: #3c1a58;
}
.header0-menu > .ant-menu a:hover {
  color: #3c1a58;
}
.header0 .ant-menu-item-selected a {
  color: #3c1a58;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100%+48px);
    opacity: 0;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #888888;
    margin-top: 4px;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
    background: #fff;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: #3c1a58;
  }
  .header0 .ant-menu a:hover {
    color: #3c1a58;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: #3c1a58;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #3c1a58;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  -webkit-transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #3c1a58;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.footer1-wrapper {
  background: #fff;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .footer1 .block {
  padding: 0 32px;
}
.footer1-wrapper .footer1 .block .logo {
  max-width: 180px;
}
.footer1-wrapper .footer1 .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .footer1 .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .footer1 .block > div {
  line-height: 24px;
}
.footer1-wrapper .footer1 .block > div a {
  color: #999;
}
.footer1-wrapper .footer1 .block > div a:hover {
  color: #3c1a58;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 100px;
  text-align: right;
  line-height: 80px;
  float: right;
}
@media screen and (max-width: 767px) {
  .footer1 {
    height: 550px;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
.card {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.15);
}
.card__bg-image {
  display: inline-block;
  object-fit: cover;
  width: 100%;
  height: 175px;
}
.card__logo {
  top: -60px;
}
.card__logo-image {
  object-fit: cover;
  width: 60px;
  height: 60px;
  border: 0.5px solid #e9e9e9;
}
.ant-card-meta-description {
  line-height: 3ex;
  height: 15ex;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.u-center-text {
  text-align: center;
}
.u-margin-bottom-small {
  margin-bottom: 2rem;
}
.u-margin-bottom-medium {
  margin-bottom: 5rem;
}
.u-margin-top-small {
  margin-top: 2rem;
}
.heading-primary {
  color: #fff;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  margin-bottom: 2rem;
}
.heading-primary--main {
  display: block;
  font-size: 2.5rem;
  font-weight: 500;
}
.heading-secondary {
  font-size: 2rem;
  font-weight: 500;
  display: inline-block;
}
.ant-card-actions {
  background: #3db389;
  padding: 0px;
  border-radius: 0px 0px 1px 1px;
}
.ant-card-actions > li {
  margin: 0px;
}
.ant-card-actions > li > span {
  padding: 10px;
  width: 100%;
}
.selector {
  position: absolute;
  display: inline;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 70%;
}

